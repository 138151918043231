import styles from './ProductGroup.module.scss';
import { useMemo } from 'react';
import PropTypes, { any } from 'prop-types';
import { Container, Row, Col } from 'components/primitives/grid';
import ProductGroupTabs from './ProductGroupTabs';
import ProductGroupProvider from './ProductGroupProvider';
import BackLink from '../backToOverviewBlock/BackLink';
import { trackProductListView, EventSource } from 'behavior/analytics';
import { useTracking } from '../productList/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { MediaGallery } from 'components/objects/mediaGallery';
import { generateKey } from 'utils/helpers';
import ProductGroupDescription from './ProductGroupDescription';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import { PageContent } from 'components/objects/pages';
import { useSanaTexts } from 'components/sanaText';
import { getFormatPrice } from 'utils/format';
import { usePriceModel } from 'utils/product';
import { useCurrencyInfo } from 'utils/hooks';
import { renderHTML } from 'utils/render';
import { Placeholder } from 'components/primitives/placeholders';
import { SimpleText, RichText } from 'components/sanaText';

const Page = ({ id, title, description, icon1, icon2, icon3, headerContent, footerContent, media, minPrice, products, facets, totalCount, totalCountInit, productsSf, facetsSf, totalCountSf, totalCountSfInit, attachments, attributes, backTo }) => { //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
  const galleryKey = useMemo(() => generateKey(), [media]);
  const dispatch = useDispatch();
  useTracking(products,
    ({ products }) => dispatch(trackProductListView({ products, source: EventSource.ProductGroup })),
    [products],
  );

  //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
  useTracking(productsSf,
    productsSf => trackproductSf(productsSf) ,
    [productsSf],
  );

  const trackproductSf = productsSf =>{
    const { products } = productsSf;    
    dispatch(trackProductListView({ products, source: EventSource.ProductGroup }));
  };

  const currencyInfo = useCurrencyInfo();
  const { price } = usePriceModel(minPrice, minPrice);
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo || price === undefined)
      return null;

    return renderHTML(formatPrice(formatAsPrice(price), currencyInfo));
  }, [currencyInfo, price]);
  const { texts: [priceLbl], loaded } = useSanaTexts(['ProductGroup_PriceFrom']);

  const noImageLarge = useSelector(state => state.settings.product.noImage.large);
  const pricePerPlaceholder = <Placeholder className={styles.pricePerPlaceholder} />;
  const isWebClient = useSelector(state => state.user.isWebClient);

  return (
    <ProductGroupProvider key={title}>
      <Container>
        <PageBreadcrumb backLinkComponent={BackLink} />
      </Container>      
      <PageContent content={headerContent} position="header" />
      <Container>
        <Row className={`${styles.displayMdBlock} clearfix`}>
          <Col xs={{ size: 12, order: 2 }} md={6}
            className={`${styles.displayMdBlock} ${styles.floatLeft}`}
          >
            <MediaGallery items={media} noImageLarge={noImageLarge} key={galleryKey} />
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={6}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <div className={styles.overview}>
              <h1 className={styles.productTitle}>{title}</h1>
            </div>
          </Col>

          {/* [156335] [F1] [Siremballage] 3.14. Level 3 product group pages */}
          <Col xs={{ size: 12, order: 3 }} md={6}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <div className={styles.description}>
              <ProductGroupDescription description={description} />              
            </div>
            <div className={styles.icons}>
              {icon1 && <img src={icon1} alt={`${title} icon 1`} className={styles.icon} draggable="false" />}
              {icon2 && <img src={icon2} alt={`${title} icon 2`} className={styles.icon} draggable="false" />}
              {icon3 && <img src={icon3} alt={`${title} icon 3`} className={styles.icon} draggable="false" />}
            </div>
          </Col>

          {/*[156334] [Siremballage] 3.13. Level 2 category pages*/}
          {isWebClient &&
            <Col xs={{ size: 12, order: 3 }} md={6}
              className={`${styles.displayMdBlock} ${styles.customOffset}`}
            >

              {/*210632_pdp_price_using_minimumPrice_of_5ranges*/}
              {(price !== 0) ? (
                <div className="price-block" title={priceLbl}>
                  <i className="visually-hidden">{priceLbl} {currencyInfo.symbol + price}</i>
                  <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
                    <div className={styles.fromPrice}>{priceLbl}</div>
                    <div className={styles.priceBlock}>
                      <div>{formattedPrice}</div>
                      <div className={styles.afterPrice}>
                        <SimpleText textKey="ProductGroup_UOM" placeholder={pricePerPlaceholder} />
                      </div>
                    </div>
                  </span>
                  </div>
                ) : console.log('Price element hidden since its zero.')
              }
              
            </Col>
          }
        </Row>
      </Container>
      {totalCountInit <= 0 && totalCountSfInit <= 0 &&
        <Container>
          <div className="msg-block">
            <RichText textKey="ProductGroup_NoResult" />
          </div>
        </Container>
      }
      <Container>
        <ProductGroupTabs products={products} facets={facets} totalCount={totalCount} totalCountInit={totalCountInit} productsSf={productsSf} facetsSf={facetsSf} totalCountSf={totalCountSf} totalCountSfInit={totalCountSfInit} description={description} attributes={attributes} attachments={attachments} groupId={id} />
      </Container>
      <PageContent content={footerContent} position="footer" />
    </ProductGroupProvider>
  );
};

Page.propTypes = {
  id: PropTypes.string.isRequired, //[156335] [F1] [Siremballage] 3.14. Level 3 product group pages
  title: PropTypes.string.isRequired,

  //[156335] [F1] [Siremballage] 3.14. Level 3 product group pages
  description: PropTypes.string,
  icon1: PropTypes.string,
  icon2: PropTypes.string,
  icon3: PropTypes.string,
  headerContent: PropTypes.array,
  footerContent: PropTypes.array,

  media: PropTypes.array,
  products: PropTypes.array,
  facets: PropTypes.object, //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
  totalCount: PropTypes.number, //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
  backTo: PropTypes.shape({
    url: PropTypes.string,
    routeData: PropTypes.object.isRequired,
  }),
};

export default Page;

//[156335] [F1] [Siremballage] 3.14. Level 3 product group pages
function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}